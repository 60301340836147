export const navLinks: Array<{
  name: string
  to: {
    en: string
    fr: string
  }
  isButton?: boolean
}> = [
  {
    name: 'About us',
    to: {
      en: '/about-us/',
      fr: '/fr/about-us/',
    },
  },
  {
    name: 'Causes',
    to: {
      en: '/causes/',
      fr: '/fr/causes/',
    },
  },
  {
    name: 'Navbar.Ansar',
    to: {
      en: '/cause/ansar/',
      fr: '/fr/cause/ansar/',
    },
  },
  {
    name: 'Navbar.Zakat',
    to: {
      en: '/cause/zakat/',
      fr: '/fr/cause/zakat/',
    },
  },
  // {
  //   name: 'Contact Us',
  //   to: '/contact-us/',
  // },
  {
    name: 'My account',
    to: {
      en: '/account/',
      fr: '/fr/account/',
    },
    // isButton: true,
  },
  {
    name: 'Donate',
    to: {
      en: '/donate/',
      fr: '/fr/donate/',
    },
    isButton: true,
  },
]
